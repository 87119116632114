import React, { useState, useEffect } from 'react'
import '../ResponsiveCss/Responsive.css'
import Web3 from 'web3';
import axios from 'axios';
import { useEthers } from "@usedapp/core";
import { useHistory } from "react-router-dom";
import Modal from 'react-modal';
import lInfo from '../../assets/images/loading-error.gif'
import TInfo from '../../assets/images/tokenerr.gif'

import { contractAddress20, contractAddress20Abi, contractAddress721, contractAddress721Abi, networkUrl } from '../Service/Service.js'

export default function Explore() {
  var history = useHistory();
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }
  const { activateBrowserWallet, account, deactivate } = useEthers();
  const [listOfNFTtemp, setListOfNFTtemp] = useState([]);
  const [exploreNFTs, setExploreNFTs] = useState([]);
  const [exploreNFTsCassette, setExploreNFTsCassette] = useState([]);

  const [exploreVinylNFTs, setExploreVinylNFTs] = useState([]);

  const [userNFTs111, setUserNFTs111] = useState([]);
  var [loading, seloading] = useState(false);
  const [limit, setLimit] = useState(6);
  const [limitVinyl, setLimitVinyl] = useState(6);

  const [userCastteCount, setUserCastteCount] = useState(0)
  const [userCastteCountTemp, setUserCastteCountTemp] = useState(0)
  const [modalIsOpen1, setIsOpen1] = React.useState(false);
  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [userVinylCount, setUserVinylCount] = useState(0)

  useEffect(() => {
    if (account == undefined || account == null || account == "") {

    }
    else {
      GetNft()
      getNftsForExplore()
      getNftsVinylForExplore()
    }


  }, [account])

  useEffect(() => {
    getNftsForExplore()
    getNftsVinylForExplore()


  }, [])



  function openModal1() {
    setIsOpen1(true);

  }

  function afterOpenModal1() {
    // references are now sync'd and can be accessed.

  }

  function closeModal1() {
    setIsOpen1(false);
  }


  function openModal2() {
    setIsOpen2(true);

  }

  function afterOpenModal2() {
    // references are now sync'd and can be accessed.

  }

  function closeModal2() {
    setIsOpen2(false);
  }


  const onLoadMore = () => {
    setLimit(limit + 6);
  }
  const onLoadMoreVinyl = () => {
    setLimitVinyl(limitVinyl + 6);
  }
//get cassette records to show
  const getNftsForExplore = async () => {
    const web3 = new Web3(Web3.givenProvider || networkUrl)
    const daiToken = new web3.eth.Contract(contractAddress721Abi, contractAddress721);

    await daiToken.methods.getMintedTokens().call().then((data) => {
      for (var i = 1; i <= 50; i++) {
        var nfttokenId = i;
        if (data.filter(x => x + "" === nfttokenId + "").length === 1) {
        } else {
          getExploreNfts(nfttokenId)
        }
      }
    }).catch((error) => {

      console.log("error ============ ", error)
    });
  }
//get vinyl records to show
  const getNftsVinylForExplore = async () => {
    const web3 = new Web3(Web3.givenProvider || networkUrl)
    const daiToken = new web3.eth.Contract(contractAddress721Abi, contractAddress721);
    await daiToken.methods.getMintedTokens().call().then((data) => {
      for (var i = 51; i <= 70; i++) {
        var nfttokenId = i;
        if (data.filter(x => x + "" === nfttokenId + "").length === 1) {
        } else {
          getExploreVinylNfts(nfttokenId)
        }
      }
    }).catch((error) => {

      console.log("error ============ ", error)
    });
  }


  const GetNft = async () => {
    const web3 = new Web3(Web3.givenProvider || networkUrl)
    var userWalletAddress;
    web3.eth.getAccounts(async (error, result) => {
      if (error) {
        console.log(error);
      } else {
        if (account == null || account == undefined || account == null) {
          userWalletAddress = result[0]

        }
        else {
          userWalletAddress = account
        }

        var i;
        var data = [];
        console.log(">>", "https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=" + contractAddress721 + "&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=8C1WCBKHRWRW1ETMUC9JE4YBG8P7JCUSEM" + "&address=" + userWalletAddress)
        await axios.get("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=" + contractAddress721 + "&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=8C1WCBKHRWRW1ETMUC9JE4YBG8P7JCUSEM" + "&address=" + userWalletAddress)
          .then(async (response) => {
            data = response.data.result
            if (data.length == 0) {
            }

            let tokenID = [];
            for (i = 0; i < data.length; i++) {
              const web3 = new Web3(Web3.givenProvider || networkUrl)
              const daiToken = new web3.eth.Contract(contractAddress721Abi, contractAddress721);
              var tokendata = data[i].tokenID
              var timestampValue = data[i].timeStamp
              await daiToken.methods.ownerOf(tokendata).call().then((data) => {
                var data = JSON.stringify(data);
                var addrss = JSON.stringify(userWalletAddress);
                var accontAdd = data.toLowerCase()
                var wallAdd = addrss.toLowerCase()
                if (accontAdd == wallAdd) {
                  var casette = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
                    29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50]

                  var vinyl = [51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70]
                  if (casette.includes(parseInt(tokendata))) {
                    setUserCastteCount(userCastteCount + 1)
                    setUserCastteCountTemp(userCastteCount + 1)
                  }
                  else if (vinyl.includes(parseInt(tokendata))) {
                    setUserVinylCount(userVinylCount + 1)
                  }
                }
                else {
                  console.log("in else")
                }
              }).catch((error) => {
                console.log("error ============ ", error)
              });
            }

          }).catch((error) => {
            console.log("Error in GetNFT Api---->", error)
          })
      }
    });


  }


  //get the vinyl
  const getExploreVinylNfts = (nfttokenId) => {
    console.log("getExploreNfts url", "https://tranquil-garden-15529.herokuapp.com/" + 'https://lostboy.mypinata.cloud/ipfs/QmT7mKRDWa3kzaWRiSo8GGHtL2FrbCTiMbhTn3TGwFwmLF/' + nfttokenId)
    axios.get("https://tranquil-garden-15529.herokuapp.com/" + 'https://lostboy.mypinata.cloud/ipfs/QmT7mKRDWa3kzaWRiSo8GGHtL2FrbCTiMbhTn3TGwFwmLF/' + nfttokenId)
      .then((response) => {
        const arr = response.data;
        const userArrayLength = exploreVinylNFTs.length;
        if (exploreVinylNFTs.filter(x => x.tokenid === arr.tokenid).length === 0) {
          exploreVinylNFTs.push(arr);
          listOfNFTtemp.push(arr);
          exploreVinylNFTs.sort((a, b) => a.tokenid - b.tokenid);
          setUserNFTs111([...exploreVinylNFTs, arr]);
        }
        else {
          console.log("duplicate Collections data")
        }

      }).catch((error) => {
        console.log("Error in GetMetadata Api---->", error)
      })
  }

  //get the cassettes
  const getExploreNfts = (nfttokenId) => {
    console.log("getExploreNfts url", "https://tranquil-garden-15529.herokuapp.com/" + 'https://lostboy.mypinata.cloud/ipfs/QmT7mKRDWa3kzaWRiSo8GGHtL2FrbCTiMbhTn3TGwFwmLF/' + nfttokenId)
    axios.get("https://tranquil-garden-15529.herokuapp.com/" + 'https://lostboy.mypinata.cloud/ipfs/QmT7mKRDWa3kzaWRiSo8GGHtL2FrbCTiMbhTn3TGwFwmLF/' + nfttokenId)
      .then((response) => {
        const arr = response.data;
        console.log("Value of array is", arr)

        const userArrayLength = exploreNFTs.length;
        if (exploreNFTs.filter(x => x.tokenid === arr.tokenid).length === 0) {

          exploreNFTs.push(arr);
          listOfNFTtemp.push(arr);
          exploreNFTs.sort((a, b) => a.tokenid - b.tokenid);
          setUserNFTs111([...exploreNFTs, arr]);
        }
        else {
          console.log("duplicate Collections data")
        }

      }).catch((error) => {
        console.log("Error in GetMetadata Api---->", error)
      })
  }


//mint mmethod for cassette
  const batchMethodCassette = async (tokenId) => {
    const web3 = new Web3(Web3.givenProvider || networkUrl)
    var contract20 = new web3.eth.Contract(contractAddress20Abi, contractAddress20);
    contract20.methods.balanceOf(account).call().then(async(value) => {
      console.log("value is", value)
      // if (value >= 15000000000000000000000) {
              if (value >= 0) {

        if (userCastteCount > 0) {
          openModal1()
        }
        else {
          const web3 = new Web3(Web3.givenProvider || networkUrl)
          seloading(true);
          var TokenId = tokenId;
          var userWalletAddress = account
          if (userWalletAddress == null || undefined) {
            history.push('/connect')
          } else {
            var block = await web3.eth.getBlock("latest");
           var gasLimit = block.gasLimit/block.transactions.length;
          console.log("gasLimit in mint", gasLimit)
      var gaslimitTosend=parseInt(gasLimit);
      var gaslimittenperc=gaslimitTosend/10;
      var finalGasLimit=gaslimitTosend + gaslimittenperc;
            var contract = new web3.eth.Contract(contractAddress20Abi, contractAddress20);
            var contract2 = new web3.eth.Contract(contractAddress721Abi, contractAddress721);
            const batch = new web3.BatchRequest();
            // batch.add(contract.methods.approve(contractAddress721, '15000000000000000000000').send({
            //   from: userWalletAddress,
            //   to: contractAddress20,
            //   chain: 1
            // }).then((value) => {
              //for cassette send type=2
              batch.add(contract2.methods.mint(TokenId, '15000000000000000000000', 2).send({
                from: userWalletAddress,
                to: contractAddress721,
                chain: 1,
                gasLimit:parseInt(finalGasLimit)
              }).then((value) => {
                window.location.href = '/mynfts';
                seloading(false);

              }).catch((error) => {
                console.log("error ============ ", error)
                seloading(false);
              }))
            // }).catch((error) => {
            //   seloading(false);
            //   console.log("error ============ ", error)
            // }))
          }
        }
      }
      else {
        openModal2();
        console.log("Insuffient Tokens")
      }
    }).catch((error) => {
      console.log("error is", error)
      seloading(false);
    })


  }
//mint mmethod for vinyl
  const batchMethodVinyl = async (tokenId) => {
    const web3 = new Web3(Web3.givenProvider || networkUrl)
    var contract20 = new web3.eth.Contract(contractAddress20Abi, contractAddress20);
    contract20.methods.balanceOf(account).call().then((value) => {
      if (value >= 30000000000000000000000) {
        seloading(true);
        var TokenId = tokenId;
        var userWalletAddress = account
        if (userWalletAddress == null || undefined) {
          history.push('/connect')
        } else {
          var contract = new web3.eth.Contract(contractAddress20Abi, contractAddress20);
          var contract2 = new web3.eth.Contract(contractAddress721Abi, contractAddress721);
         
          
          const batch = new web3.BatchRequest();
          batch.add(contract.methods.approve(contractAddress721, '30000000000000000000000').send({
            from: userWalletAddress,
            to: contractAddress20,
            chain: 1
          }).then((value) => {
            //for vinyl send type=1
            batch.add(contract2.methods.mint(TokenId, '30000000000000000000000', 1).send({
              from: userWalletAddress,
              to: contractAddress721,
              chain: 1
            }).then((value) => {
              window.location.href = '/mynfts';
              seloading(false);

            }).catch((error) => {
              console.log("error ============ ", error)
              seloading(false);
            }))
          }).catch((error) => {
            seloading(false);
            console.log("error ============ ", error)
          }))

        }
      } else {
        openModal2();
        console.log("Insuffient Tokens")
      }
    }).catch((error) => {
      console.log("error is", error)
      seloading(false);
    })

  }

  return (
    <div>
      {/* <!-- Third Section --> */}
      <section class="third-section" id="explore">
        <h2>EXPLORE</h2>
        <div class="container">
          <h6>Cassettes</h6>
          <div class="nft-panel">
            {console.log("exploreNFTs in return MyNFT", exploreNFTs)}
            {/* {exploreNFTs.length > 0 ? */}
                        {exploreNFTsCassette.length > 0 ?

              <div>
                {/* slice(0,limit). */}
                {/* {exploreNFTs.slice(0, limit).map((item) => ( */}
                  {exploreNFTsCassette.slice(0, limit).map((item) => (
                  <div class="collection-card">
                    <div class="source">
                      {(item.image).includes(".mp4" || ".gif") ?
                        <video width="320" height="240" controls controls controlsList="nodownload">
                          <source src={item.image} type="video/mp4" />
                        </video> : <img src={item.image} alt="Avatar" />
                      }
                    </div>
                    <div class="details">
                      <div class="left">
                        <h3>{item.name}</h3>
                        <h4>Created By : <span><a href="https://etherscan.io/address/0xf495a24f4f0638a6eaa40f8a0d031052fae58a86" target="_blank">Lostboy (0xf49...8a86)</a></span></h4>
                        {console.log("userCastteCount in return", userCastteCount)}
                        <button onClick={() => { batchMethodCassette(item.tokenid) }}>Mint Now</button>
                      </div>
                      <div class="right">
                        <span>Price</span>
                        <h5><span>LOST</span> 15K</h5>
                      </div>
                    </div>
                  </div>
                )).sort()}

              </div> : null}
            {/* {limit == 54 ? null : <div class="load-more-button"><button onClick={() => { onLoadMore() }} class="loadmore">Load More</button> </div>} */}
            {loading ?
              <div class="waiting-overlay">
                <div id="text">
                  <div class="loading-img"></div>
                  <div class="loader"></div></div>
                <span>Please wait your minting is in progress...</span>
              </div> : null}

          </div>
          <Modal
            isOpen={modalIsOpen1}
            onAfterOpen={afterOpenModal1}
            onRequestClose={closeModal1}
            style={customStyles}
            contentLabel="Example Modal"
            shouldCloseOnOverlayClick={false}
          >
            <div class="infotext_Modal">
              <img class="infoImg" src={lInfo} />
              <p>Cannot Mint More than 1 Cassette</p>
              <button class="infobtnModal" onClick={() => { closeModal1() }}>ok</button>
            </div>
          </Modal>

          <Modal
            isOpen={modalIsOpen2}
            onAfterOpen={afterOpenModal2}
            onRequestClose={closeModal2}
            style={customStyles}
            contentLabel="Example Modal"
            shouldCloseOnOverlayClick={false}
          >
            <div class="infotext_Modal">
              <img class="infoImg" src={TInfo} />
              <h2>Insufficient Tokens!</h2>
               <p>Please add tokens to your wallet</p>
              <button class="infobtnModal" onClick={() => { closeModal2() }}>ok</button>
            </div>
          </Modal>




        </div>

        <div class="container">
          <h6>Vinyl Records</h6>
          <div class="nft-panel">
            {console.log("exploreNFTs in return MyNFT", exploreVinylNFTs)}
            {exploreVinylNFTs.length > 0 ?
              <div>
                {exploreVinylNFTs.slice(0, limitVinyl).map((item) => (
                  <div class="collection-card">
                    <div class="source">
                      {(item.image).includes(".mp4" || ".gif") ?
                        <div dangerouslySetInnerHTML={{
                          __html: `
                      <video width="320" height="240" controls controls controlsList="nodownload">
                      <source src=${item.image} type="video/mp4" />
                    </video> 
                      `}}>

                        </div>

                        : <img src={item.image} alt="Avatar" />
                      }
                    </div>
                    <div class="details">
                      <div class="left">
                        <h3>{item.name}</h3>
                        <h4>Created By : <span><a href="https://etherscan.io/address/0xf495a24f4f0638a6eaa40f8a0d031052fae58a86" target="_blank">Lostboy (0xf49...8a86)</a></span></h4>
                        {console.log("userCastteCount in return", userCastteCount)}
                        <button onClick={() => { batchMethodVinyl(item.tokenid) }}>Mint Now</button>
                      </div>
                      <div class="right">
                        <span>Price</span>
                        <h5><span>LOST</span> 30K</h5>
                      </div>
                    </div>
                  </div>
                )).sort()}

              </div> : null}
            {console.log("limitVinyl in console", limitVinyl)}

            {/* {limitVinyl == 24 ? null : <div class="load-more-button"><button onClick={() => { onLoadMoreVinyl() }} class="loadmore">Load More</button> </div>} */}

            {loading ?
              <div class="waiting-overlay">
                <div id="text">
                  <div class="loading-img"></div>
                  <div class="loader"></div></div>
                <span>Please wait your minting is in progress...</span>
              </div> : null}

          </div>
        </div>
      </section>
    </div>
  )
}
