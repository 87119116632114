import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useEthers } from "@usedapp/core";
import { doc, getDocs, getDoc, collection, setDoc, updateDoc } from "firebase/firestore";
import Modal from 'react-modal';
import Web3 from 'web3';
import Footer from '../../components/Footer/Footer.js';
import Navbar from '../../components/NavBar/Navbar.js';
import CrudMethods from '../../config/CrudMethods';
import '../ResponsiveCss/Responsive.css';
import { db } from "./../../config/firebase-config";

import OurCollection from '../OurCollections/OurCollection.js';
import Explore from '../Explore/Explore.js';
import Faq from '../Faq/Faq.js';
const LandingPage = () => {
  return (
    <div>
      <Navbar />
      <OurCollection />
      <Explore />
      <Faq />
    </div>
  );
};

export default LandingPage;