import React, { useEffect, useState, useRef } from 'react'
import { useEthers } from "@usedapp/core";
import InfoImgMeta from '../../assets/images/metamask.gif';
import Navbar from '../NavBar/Navbar.js';
import Footer from '../Footer/Footer'
import Modal from 'react-modal';
import { doc, setDoc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase-config";


export default function ConnectMetaMask() {
    const ethereum = window.ethereum;

    const { activateBrowserWallet, account, deactivate } = useEthers();
    const [modalIsOpen3, setIsOpen3] = React.useState(false);
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    }
    const [value, setValue] = useState();
    const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;


    useEffect(() => {
        if (reloadCount < 1) {
            sessionStorage.setItem('reloadCount', String(reloadCount + 1));
            window.location.reload();
        } else {
            sessionStorage.removeItem('reloadCount');
        }
    }, []);


    function openModal3(value) {
        setIsOpen3(true);

    }

    function afterOpenModal3() {

    }

    function closeModal3() {
        setIsOpen3(false);
    }

    // Connect Wallet Method
   
    const handleWalletChange = async () => {

        console.log("account in handleWalletChange111", account)
        console.log("Is handleWalletChange")
        if (ethereum) {
            console.log("Is handle wallet change calledRR")
            ethereum.on('accountsChanged', function (accounts) {
                console.log("accounts are", accounts)

                if (accounts.length > 0) {
                    console.log("inside if")

                    var address = accounts[0]
                    console.log("address is21", address)
                    getDoc(doc(db, "Users", address)).then(async (docSnap) => {
                        if (docSnap.exists()) {
                            console.log("Document data:", docSnap.data());
                            window.location.reload();

                        } else {
                            console.log("No such document!>>");
                            const washingtonRef = doc(db, "Users", accounts[0]);
                            const RegisterData = {
                                address: '' + accounts[0],
                                name: '',
                                IsAdmin: false,
                            };
                            console.log("RegisterData", RegisterData)
                            const aaa = await setDoc(washingtonRef, RegisterData);
                        }
                    })
                }
            });

        }

    }
   
   
   
   
    const handleConnectWallet = () => {
        login();
    }

    const login = async () => {
        const permissions = await ethereum.request({
            method: 'wallet_requestPermissions',
            params: [{
                eth_accounts: {},
            }]
        });
        await activateBrowserWallet();
        await handleWalletChange();
        window.location.href = "/"
    }
    return (
        <div>
            <Navbar />
            <section class="first-section">
                <div class="connect-metamask">
                    <img src={InfoImgMeta} />
                    {account ?

                        <div><h2>Metamask Connected!</h2>
                            <p>You Can Disconnect MetaMask.</p>
                            <div className={"btn-connect"}>
                                <button class="infobtnModal2">{account ? <span> {account &&
                                    `${account.slice(0, 6)}...${account.slice(
                                        account.length - 4,
                                        account.length
                                    )}`}</span>: "CONNECT WALLET"}</button>
                            </div>
                        </div>
                        :
                        {
                            ...ethereum && ethereum.isMetaMask ?
                                <div>
                                    <h2>Connect Metamask!</h2>
                                    <p>Please Connect MetaMask First.</p>
                                    <div className={"btn-connect"} onClick={() => { handleConnectWallet() }}>

                                        <button class="infobtnModal2">Connect</button>
                                    </div>
                                </div>
                                :
                                <div>
                                    <h2>Install Metamask!</h2>
                                    <p>Please Install MetaMask First.</p>
                                </div>
                        }

                    }
                </div>
            </section>
            <Modal
                isOpen={modalIsOpen3}
                onAfterOpen={afterOpenModal3}
                onRequestClose={closeModal3}
                style={customStyles}
                shouldCloseOnOverlayClick={false}
                contentLabel="Example Modal"
            >
                <div class="infotext_Modal">
                    <img class="infoImg" src={InfoImgMeta} />
                    <h2>Metamask!</h2>
                    <p>Please Install MetaMask First.</p>
                    <button class="infobtnModal" onClick={() => { closeModal3() }}>ok</button>
                </div>
            </Modal>
            <Footer />
        </div>
    )
}
