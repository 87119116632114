import React from 'react'
import '../ResponsiveCss/Responsive.css'
export default function Faq() {
  return (
    <div>
      <section class="faq-section" id="faq">
        <div class="container">
          <h2>FAQ's</h2>
          <div class="acc-panel">
            <button class="accordion">How Can I Claim A Cassette or Vinyl?</button>
            <div class="panel">
              <p>With $LOST!</p>
            </div>

            <button class="accordion">How Can I Earn $LOST?</button>
            <div class="panel">
              <p>Through staking your lostboys and holding your lostgirls.</p>
            </div>

            <button class="accordion">How many Cassettes and Vinyl Records are available to claim?</button>
            <div class="panel">
              <p>1 Vinyl/Cassette per wallet address</p>
            </div>

            <button class="accordion">What does holding this NFT give me?</button>
            <div class="panel">
              <p>Each holder of a Cassette or Vinyl NFT owns the complete rights to the Art and Music of the NFT. You will have full masters right for the song within your NFT. you can do whatever you would like with it, from posting it on spotify to using it in any content you would like to make, it is entirely up to the NFT holder.
                Once sold, all ownership and rights go to the new owner.</p>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}
