import React from 'react'
import '../ResponsiveCss/Responsive.css'
import img1 from '../../assets/images/1.png'
import img2 from '../../assets/images/2.png'
import img3 from '../../assets/images/3.png'
import img4 from '../../assets/images/4.png'
import img5 from '../../assets/images/5.png'
import img6 from '../../assets/images/6.png'
import img7 from '../../assets/images/7.png'

export default function OurCollection() {
    return (
        <div>
            {/* <!-- Second Section --> */}
            <section class="second-section" id="collection">
                <h2 class="">THE ARTIST EXPERIENCE</h2>
                <div class="image-caro">
                    <div class="card-carousel">
                        <div class="my-card"><img src={img1} /></div>
                        <div class="my-card"><img src={img2} /></div>
                        <div class="my-card"><img src={img3} /></div>
                        <div class="my-card"><img src={img4} /></div>
                        <div class="my-card"><img src={img5} /></div>
                        <div class="my-card"><img src={img6} /></div>
                        <div class="my-card"><img src={img7} /></div>



                    </div>
                    <div class="card-carousel1"><img src={img1} /></div>
                </div>
                <div class="text">
                    <p>Welcome to the Artist Experience - in this page you can find our limited lostboy Cassette and Vinyl collection. 130 total new songs, all freshly produced and ready to be given to you.</p>
                    <p>Every piece is a 1/1. Each “Cassette” comes with 1 song while each Vinyl comes with 4 full songs. Stems are included for the cassettes but not vinyls. Both limited tokens will grant you complete rights to the Art and Music of the NFT.</p>
                    <p>Claiming one of these Tokens will grant you access to our Artist Experience. Together, we will distribute your track on all Streaming Platforms.</p>
                    <p>Pick the Token that suits you, the one that you can relate to the most, and the one that you think would perform best. Help us pick LOSTBOYs next release.</p>
                    <p>Goodluck!</p>
                </div>
            </section>
        </div>
    )
}
