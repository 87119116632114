
import React, { useState, useEffect } from 'react'
import '../ResponsiveCss/Responsive.css'
import { useEthers } from "@usedapp/core";
import { Link, useHistory, useLocation } from 'react-router-dom';
import Navbar from '../NavBar/Navbar.js';
import Web3 from 'web3';
import axios from "axios";
import '../ResponsiveCss/Responsive.css';
import { contractAddress721, contractAddress721Abi, networkUrl } from '../Service/Service.js'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

export default function MyNFT() {
  const [listOfNFTtemp, setListOfNFTtemp] = useState([]);
  const [listOfNFT, setListOfNFT] = useState([]);
  const { activateBrowserWallet, account, deactivate } = useEthers();
  const [searchKey, setSearchKey] = useState('');
  var [metaData1, setMetaData1] = useState([]);
  const [show, setShow] = useState(false);
  const [tokenId, setTokenId] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [userNFTs, setUserNFTs] = useState([]);
  const [userNFTs111, setUserNFTs111] = useState([]);
  const [ggArray, setggArray] = useState([]);
  const [ggArrayTemp, setggArrayTemp] = useState([]);
  const [loading, setloading] = useState(true);
  const [userAddress, setUserAddress] = useState('');

  const ethereum = window.ethereum;
  var userWalletAddress1 = window.localStorage.getItem("walletAddress");

  let subtitle;
  useEffect(async () => {
    const web3 = new Web3(Web3.givenProvider || networkUrl)
    const chainid = await new web3.eth.getChainId()
    if (chainid == 1) {
      setTimeout(
        () => {
          if (ethereum && ethereum.isMetaMask) {
            ChainId()
          }
          else {
            setloading(false)
          }

        },
        6000
      );
    }
    else {
      setloading(false)
    }

  }, []);

  useEffect(() => {
    var userWalletAddress = window.localStorage.getItem("walletAddress");
    console.log("userWalletAddress in MyNFT.js", userWalletAddress)
    setUserAddress(userWalletAddress);
  }, [account]);


  const ChainId = async () => {
    const web3 = new Web3(Web3.givenProvider || networkUrl)
    const chainid = await new web3.eth.getChainId()
    console.log("Chain ID123", chainid)
    if (chainid == 1) {
      await GetNft()
    }
    else {
      setloading(false);
    }

  }
//get nfts of contract from etherscan api
  const GetNft = async () => {
    console.log("account in GetNft", account);
    var userWalletAddress = window.localStorage.getItem("walletAddress");
    console.log("userWalletAddress in MyNFT.js", userWalletAddress)
    var i;
    var data = [];
    console.log(">>", "https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=" + contractAddress721 + "&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=8C1WCBKHRWRW1ETMUC9JE4YBG8P7JCUSEM" + "&address=" + userWalletAddress)
    await axios.get("https://api.etherscan.io/api?module=account&action=tokennfttx&contractaddress=" + contractAddress721 + "&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=8C1WCBKHRWRW1ETMUC9JE4YBG8P7JCUSEM" + "&address=" + userWalletAddress)
      .then(async (response) => {
        data = response.data.result
        console.log("data Array---->>", data)
        if (data.length == 0) {
          setloading(false)
        }

        let tokenID = [];
        for (i = 0; i < data.length; i++) {
          const web3 = new Web3(Web3.givenProvider || networkUrl)
          const daiToken = new web3.eth.Contract(contractAddress721Abi, contractAddress721);
          var tokendata = data[i].tokenID
          var timestampValue = data[i].timeStamp
          console.log("tokendata", tokendata)

          console.log("daiToken12", daiToken.methods)
          await daiToken.methods.ownerOf(tokendata).call().then((data) => {
            console.log("data---->", JSON.stringify(data))
            console.log("userWalletAddress---->", JSON.stringify(userWalletAddress));
            var data = JSON.stringify(data);
            var addrss = JSON.stringify(userWalletAddress);
            var accontAdd = data.toLowerCase()
            var wallAdd = addrss.toLowerCase()
            if (accontAdd == wallAdd) {
              GetUrl(tokendata);
            }
            else {
              console.log("in else")
            }
          }).catch((error) => {
            setloading(false);
            console.log("error ============ ", error)
          });
        }

      }).catch((error) => {
        setloading(false);
        console.log("Error in GetNFT Api---->", error)
      })
  }

  const GetUrl = (tokenId) => {
    const web3 = new Web3(Web3.givenProvider || networkUrl)
    const daiToken = new web3.eth.Contract(contractAddress721Abi, contractAddress721);
    const myMethod = daiToken.methods['tokenURI(uint256)'](tokenId)
    myMethod.call().then(function (result) {
      console.log("url is", result)
      GetMetaData(result);
    })
  }
  const GetMetaData = (url, calculatedHrs) => {
    // axios.get("https://tranquil-garden-15529.herokuapmp.co/" + url)
    axios.get(url)
      .then((response) => {
        const arr = response.data;
        const userArrayLength = userNFTs.length;
        if (userNFTs.filter(x => x.tokenid === arr.tokenid).length === 0) {
          userNFTs.push(arr);
          listOfNFTtemp.push(arr)
          setUserNFTs111([...userNFTs, arr]);
          setloading(false);
        }
        else {
          console.log("duplicate Collections data")
        }

      }).catch((error) => {
        setloading(false);
        console.log("Error in GetMetadata Api---->", error)
      })
  }

  return (
    <div>
      <Navbar />
      <div class="third-section">
        <h2>My NFT's</h2>
        {console.log("userNFTs in return MyNFT", userNFTs)}
        {userNFTs.length > 0 ?
          <div class="container">
            {userNFTs.map((item) => (
              <div class="collection-card">
                <div class="source">
                  {(item.image).includes(".mp4" || ".gif") ?
                    <video width="320" height="240" controls>
                      <source src={item.image} type="video/mp4" />
                    </video> : <img src={item.image} alt="Avatar" />
                  }

                </div>
                <div class="details">
                  <div class="left">
                    <h3>{item.name}</h3>
                    <h4>Created By : <span><a href="https://etherscan.io/address/0xf495a24f4f0638a6eaa40f8a0d031052fae58a86">Lostboy (0xf49...8a86)</a></span></h4>
                    <h4>owned by : <span>{userWalletAddress1.slice(0, 6)}...{userWalletAddress1.slice(-2)}</span></h4>
                    <a href="https://opensea.io/" target="_blank"><button>Sell Now</button></a>
                  </div>
                  <div class="right">
                    {/* <span>Price</span>
                    <h5>RT 1</h5> */}
                    {/* {console.log("Zip file in return", CryptoJS.AES.decrypt(item.downloadUrl, "RoopalDevelop5*").toString(CryptoJS.enc.Utf8))} */}
                    {/* <a download="file.zip" href={(CryptoJS.AES.decrypt(item.downloadUrl, "RoopalDevelop5*").toString(CryptoJS.enc.Utf8))} class="download"><i class="fas fa-download"></i> Download</a> */}
                    <a download="file.zip" href={"https://lostboy.mypinata.cloud/ipfs/QmdF2c5ajhcyuGnkVVyFbasDUyPALHWvNhijJroVMjMjeF/"+item.tokenid+".zip"} class="download"><i class="fas fa-download"></i> Download</a>

                  </div>
                </div>
              </div>

            )).sort()}

          </div> : <div class="container"><div class="nft-panel"> <h3 class="no-found">{loading ? null : <div>No NFTs Found! Please Mint Some NFTs <a href=""><Link to="/#explore">here</Link></a></div>}</h3></div></div>}

        {loading ?
          <div class="waiting-overlay">
            <div id="text">
              <div class="loading-img"></div>
              <div class="loader"></div></div>
            <span>Please wait your NFT's is in progress...</span>
          </div> : null}
      </div>
    </div>
  )
}





