import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import '../ResponsiveCss/Responsive.css';
import footerlogo from '../../assets/images/footer-logo.png'


const Footer = () => {

    const search = useLocation().search;
    const test = new URLSearchParams(search).get('test');


    return (
        <div>
            {/* <!-- Footer Section --> */}
            <section class="footer-section">
                <div><img src={footerlogo} alt="Lostboy Collection" /></div>
                <div><a href="https://discord.com/invite/lostboynft" target="_blank"><i class="fab fa-discord"></i></a>
                    <a href="https://twitter.com/lostboynft" target="_blank"><i class="fab fa-twitter"></i></a></div>
                <div>&copy; 2021 <a href="https://www.lostboy.la/">lostboy.la</a> - All right reserved</div>
                <div><a href="https://etherscan.io/address/0xf495a24f4f0638a6eaa40f8a0d031052fae58a86" target="_blank"><u>Verified Smart Contract</u></a></div>
            </section>
        </div>
    )
}

export default Footer;