// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore';

// Stag Creds
// const firebaseConfig = {
//   apiKey: "AIzaSyCIQS-NF3HBGWsj4MrO2RvkiIOdixgIeOA",
//   authDomain: "legendsofasianstag.firebaseapp.com",
//   projectId: "legendsofasianstag",
//   storageBucket: "legendsofasianstag.appspot.com",
//   messagingSenderId: "819219516932",
//   appId: "1:819219516932:web:020f2444054c2c6740af51",
//   measurementId: "G-Z56V19VMC1"
// };
const firebaseConfig = {
  apiKey: "AIzaSyCD1BB4O5vci6LO-OyfzXmPZtVPs0cKfpY",
  authDomain: "lostboy-b5ab9.firebaseapp.com",
  projectId: "lostboy-b5ab9",
  storageBucket: "lostboy-b5ab9.appspot.com",
  messagingSenderId: "128714566961",
  appId: "1:128714566961:web:19642e068d3ff52699d410",
  measurementId: "G-TNEZ0N3F20"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);