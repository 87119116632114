import React, { useState, useEffect, useContext, useReducer } from 'react';
import '../ResponsiveCss/Responsive.css';
import { useEthers } from "@usedapp/core";
import { Link, NavLink, useLocation } from 'react-router-dom';
import Identicon from '../Identicon';
import CrudMethods from '../../config/CrudMethods';
import { ropstenBaseURL, baseURL, ETHER_SCAN_API_KEY } from '../../constants/global';
import Web3 from 'web3';
import { db } from "./../../config/firebase-config";
import lostboylogo from '../../assets/images/lostboy-logo.png'
import lInfo from '../../assets/images/loading-error.gif'
import Modal from 'react-modal';
import { doc, setDoc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";


import { useHistory } from "react-router-dom";

const Navbar = ({ height, width }) => {

    const [showMenuDiv, setShowMenuDiv] = useState(width < 769 ? false : true);
    const { activateBrowserWallet, account, deactivate } = useEthers();
    const [getBalance, setBalance] = useState('0');
    const [getLogin, setLogin] = useState({});
    const [nftERC721, setNftERC721] = useState('');
    const [page, setPage] = useState(1);
    var [metaData, setMetaData] = useState([]);
    var [metaDataDefault, setMetaDataDefault] = useState('');
    var [metaData1, setMetaData1] = useState([]);
    const [navigatorDynamics, setNavigatorDynamics] = useState([]);
    const [userDetails, setUserDetails] = useState([]);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpen2, setIsOpen2] = React.useState(false);
    const [modalIsOpen3, setIsOpen3] = React.useState(false);




    const [mynftColor, setmynftColor] = useState('white');
    const [exploreColor, setexploreColor] = useState('white');
    const [mintColor, setmintColor] = useState('white');
    const [aboutColor, setaboutColor] = useState('white');
    const [roadmapColor, setroadmapColor] = useState('white');
    const [ourTeamColor, setouTeamColor] = useState('white');
    const [faqColor, setfaqColor] = useState('white');
    const [text, setText] = useState('hello')
    var history = useHistory();

    const ethereum = window.ethereum;
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    }
    const search = useLocation().search;
    const test = new URLSearchParams(search).get('test');
    console.log("name is1123", test)
    // {test=="on"?"":Explore}
    useEffect(() => {
        const pathname = window.location.pathname
        console.log("pathname is", pathname)
        if (pathname == "/MyNft") {
            setmynftColor("yellow")
            setexploreColor("white")
            setfaqColor("white")
            setmintColor("white")
            setaboutColor("white")
            setroadmapColor("white")
            setouTeamColor("white")

        }
        else if (pathname == "/BuyNft") {
            setmynftColor("white")
            setexploreColor("yellow")
            setfaqColor("white")
            setmintColor("white")
            setaboutColor("white")
            setroadmapColor("white")
            setouTeamColor("white")
        }
        else {

        }
    }, [])


    useEffect(async () => {
        { ethereum && ethereum.isMetaMask ? ChainId() : null }
        { ethereum && ethereum.isMetaMask ? onNetworkChange() : null }

    }, []);

    const switchNetwork = async () => {
        closeModal()
        await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x1' }], // chainId must be in hexadecimal numbers
        });
        window.location.reload();
    }

    const ChainId = async () => {
        const web3 = new Web3(Web3.givenProvider)
        console.log("we3 is", web3);
        const chainid = await new web3.eth.getChainId()
        console.log("Chain ID====", chainid)
        if (chainid == 1) {

        }
        else {
            openModal();
        }

    }

    const onNetworkChange = () => {
        ethereum.on('chainChanged', (chainId) => {
            console.log("Chain Idd is", chainId)
            if (chainId == 0x1) {
            } else {
                openModal();
            }
        });
    }

    function openModal2(value) {
        setIsOpen2(true);

    }

    function afterOpenModal2() {
        // references are now sync'd and can be accessed.

    }

    function closeModal2() {
        setIsOpen2(false);
    }

    function openModal3(value) {
        setIsOpen3(true);

    }

    function afterOpenModal3() {

    }

    function closeModal3() {
        setIsOpen3(false);
    }

    function openModal(value) {
        setIsOpen(true);

    }

    function afterOpenModal() {

    }

    function closeModal() {
        setIsOpen(false);
    }


    const myStorage = window.localStorage;
    useEffect(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Height of the Page ' + height, '  Width of the Page  ' + width);
            console.log('Login Data ', JSON.parse(myStorage.getItem("loginsession")));
        }
    }, [height, width]);

    useEffect(async () => {
        if (ethereum && ethereum.isMetaMask) {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('MetaMask is Installed')
            }
        } else {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                console.log('MetaMask is not Installed')
            }
        }

        const json = myStorage.getItem("loginsession");
        const savedLoginSession = JSON.parse(json);
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Login Info: ', savedLoginSession);
            console.log('djkgksg  ', account)
        }
        if (savedLoginSession) {
            setLogin(savedLoginSession);
            var getCustomAddress = savedLoginSession.wallet_id;
            var setData = getCustomAddress;
            if (setData == null) {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log('Local Data is Null or Undefined', setData);
                }
            }
            else {
            }
        } else {
            setNftERC721([])
        }
    }, []);

    useEffect(async () => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Account Chnaged handler ', JSON.stringify(account));
        }
        if (account == undefined || account.length == 0) {
            myStorage.clear(); setLogin({});
        }
        console.log("account ======== ", account)
        getUserDetail(account);
        handleWalletChange();
    }, [account]);

    const getUserDetail = async (data) => {
        if (data) {
            const docRef = doc(db, "Users", (data.toLowerCase()));
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                setUserDetails(docSnap.data())
            }
        }
    }

    const handleWalletChange = async () => {

        console.log("account in handleWalletChange", account)
        console.log("Is handleWalletChange")
        if (ethereum) {
            console.log("Is handle wallet change called")
            ethereum.on('accountsChanged', function (accounts) {
                console.log("accounts are", accounts)

                if (accounts.length > 0) {
                    console.log("inside if")

                    var address = accounts[0]
                    console.log("address is21", address)
                    getDoc(doc(db, "Users", address)).then(async (docSnap) => {
                        if (docSnap.exists()) {
                            console.log("Document data:", docSnap.data());
                            window.location.reload();

                        } else {
                            console.log("No such document!>>");
                            const washingtonRef = doc(db, "Users", accounts[0]);
                            const RegisterData = {
                                address: '' + accounts[0],
                                name: '',
                                IsAdmin: false,
                            };
                            console.log("RegisterData", RegisterData)
                            const aaa = await setDoc(washingtonRef, RegisterData);
                        }
                    })
                }
            });

        }

    }

    const metamaskErrorhandle = () => {

        history.push("/connect");


    }

    const gotoMynft = () => {
        if (account == undefined || account == "" || account == null) {
            metamaskErrorhandle()
        }
        else {
            // window.href="/mynfts"
            history.push("/mynfts");
        }

    }

    return (
        <section class="first-section" id="home">
            <div class="container">
                <div class="header">
                    <nav class="navigation-menu-left">
                        <ul>
                            <li><a href="https://lostboy.io/" target="_blank">HOME</a></li>
                            {/* <li><a href="/lostboy/#collection">COLLECTION</a></li> */}
                            <li><a href="/#explore">EXPLORE</a></li>
                            <li><a onClick={() => { gotoMynft() }} href="#">MY NFTS</a></li>
                        </ul>
                    </nav>
                    <div class="logo"><a href="/"><img src={lostboylogo} alt="Lostboy NFT's" /></a></div>
                    <nav class="navigation-menu-right">
                        <ul>

                            <li><a href="https://discord.com/invite/lostboynft" target="_blank"><i class="fab fa-discord"></i></a>
                                <a href="https://twitter.com/lostboynft" target="_blank"><i class="fab fa-twitter"></i></a>
                                <a href="https://www.instagram.com/lostboynft/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            <li><a onClick={() => window.location.reload()} href="/connect" class="join-button">{account ? <span> {account &&
                                `${account.slice(0, 6)}...${account.slice(
                                    account.length - 4,
                                    account.length
                                )}`}</span> : "CONNECT WALLET"}</a></li>
                        </ul>
                    </nav>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div class="infotext_Modal">
                    <img class="infoImg" src={lInfo} />
                    <h2>Wrong Network!</h2>
                    <p>Please change it to Ethereum Network!</p>
                    <button class="infobtnModal" onClick={() => { switchNetwork() }}>ok</button>
                </div>
            </Modal>
        </section>
    );
};

export default Navbar;

